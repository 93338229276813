export const required = (message) => (value) => {
  return (!value || (value + '').trim() === '' ? message : undefined);
}

export const maxLength = (max) => (value) => {
  return value && value.trim().length > max ? `长度不超过${max}字符` : undefined
}

export const min = (min) => (value) => {
  const myValue = Number(value);
  return  !isNaN(myValue) && myValue < min ? `最小${min}` : undefined;
}

export const max = (max) => (value) => {
  const myValue = Number(value);
  return !isNaN(myValue) && myValue > max ? `最大${max}` : undefined;
}

export const isNumber = (value) => {
  const pattr = /^[0-9]*$/;
  return pattr.test(value) || !value ? undefined : '请输入整数';
}